import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../config";
import { addAnItem } from "../../store/cart-actions";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
var parse = require("html-react-parser");
const SingleCard = (props) => {
  if (props.data) {
    var {
      img,
      imgClass,
      componentClass,
      title,
      subTitle,
      content,
      buttonText,
      buttonLink,
      secondaryButtonText,
      secondaryButtonLink,
      border,
      target,
      category,
      id,
      target,
      cardLink,
    } = props.data;
    var link = props.link;
  }
  const [prod, setProd] = useState({});
  const products = useSelector((state) => state.product.products);
  useEffect(() => {
    if (id) setProd(products.find((p) => p.id === id));
  }, [products.size, products, prod, id]);

  if (id && prod) {
    var { name, content, subTitle, category, price, per, contentList } = prod;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // debugger;
  const getPlan = () => {
    toast(prod.name + " Ready for checkout.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(addAnItem(prod));
    navigate("/cart");
  };

  if (border) {
    var borderstyle = border === "all" ? "border" : `border-${border}`;
  }
  const cardContent = (
    <>
      {img && (
        <Card.Img
          variant="top"
          src={img}
          className={imgClass ? imgClass : ""}
        />
      )}
      <Card.Body className="">
        {title && (
          <Card.Title className="text-center">{parse(title)}</Card.Title>
        )}
        {subTitle && <p className="fs-9 text-primary">{parse(subTitle)}</p>}
        {name && <Card.Title className="text-center">{name}</Card.Title>}
        {category && (
          <div className="font-9 py-2 text-center category">{category}</div>
        )}
        {price && !category && (
          <div className="font-1_4 py-2 text-center">
            ${price}/{per ? per : "mo"}
          </div>
        )}
        {content && <div className="">{parse(content)}</div>}
        {contentList &&
          contentList.map((list, idx) => {
            return (
              <div key={idx}>
                <i
                  className={`${list.sectionIcon ? list.sectionIcon : ""} pe-2`}
                ></i>
                {list.sectionName}
                {list.items && (
                  <ul>
                    {list.items.map((itm, i) => {
                      return <li key={i}>{itm.item}</li>;
                    })}
                  </ul>
                )}
              </div>
            );
          })}
      </Card.Body>
      <div className="card-buttons">
        {secondaryButtonText && (
          <a href={secondaryButtonLink} className="btn btn-light mx-2">
            {secondaryButtonText}
          </a>
        )}
        {buttonText && (
          <a
            href={buttonLink}
            onClick={() => {
              window.analytics.track("clicked " + buttonText, {
                title: "Clicked on cards",
              });
            }}
          >
            <Button
              variant="primary"
              target={target}
              className="m-2 text-center"
              onClick={id ? getPlan : () => {}}
            >
              {parse(buttonText)}
            </Button>
          </a>
        )}
      </div>
    </>
  );

  if (cardLink)
    return (
      <Card
        className={`single-card ${
          props?.className ? props.className : ""
        } pt-2 `}
      >
        <a href={cardLink} target={target}>
          <div className={`${borderstyle ? borderstyle : ""}`}>
            {cardContent}
          </div>
        </a>
      </Card>
    );

  return (
    <Card
      className={`single-card ${props?.className ? props.className : ""}  ${
        componentClass ? componentClass : ""
      }`}
    >
      <div className={`${borderstyle ? borderstyle : ""} wrapper`}>
        {cardContent}
      </div>
    </Card>
  );
};

export default SingleCard;
