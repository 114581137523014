import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import User from "../../helpers/login/userData";
import { Modal } from "react-bootstrap";
/**
 *
 * @Components props.data
 * formContainer[] -> array of {groupname, fields[]}
 *
 * groupname: gives a title to group of fields mentioned in fields object
 * fields[]: array of {label, colclass, type, details},
 *            for groupname "Buttons" it will be array of {buttonText, colclass,color, link},
 *            for type: "Select" and "Radio", it will accept options (which is an array) instead of details
 *            type: "Radio" , also accepts a field "optionClass" to display options in col format
 *
 * label: field Label
 * colclass: css to show how many columns the field will take
 * type: to identify the type of field can have values : Select, Radio, Control, TextArea, State(for dropdown of US states), Review, Info
 * details: its an object {type (for control structures - like email, text, date etc), placeholder, text (a little note below the field)}
 */

const FormContainer = (props) => {
  const navigate = useNavigate();
  var clicked;
  const [states, setStates] = useState([]);
  const statesAPIurl = `/json/common/states.json`;

  const user = User();
  const email = user?.email;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    //console.log("USER in form ", user);
    axios.get(statesAPIurl).then((res) => {
      setStates(res.data);
    });
  }, [statesAPIurl]);

  if (props) {
    var formContainer = props.data;
  }

  const btnClick = (btn) => {
    if (btn?.link) {
      navigate(btn?.link);
    }
  };

  const postData = (data, url) => {
    if (url) axios.post(url, data).then((res) => console.log(res));
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const formdata = Object.fromEntries(new FormData(e.target)?.entries());
    if (clicked?.toastMessage) {
      toast(clicked.toastMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    postData(formdata, clicked?.postUrl);
    e.target.reset();
    if (clicked?.link) {
      navigate(clicked?.link);
    }
    if (props?.clickFx) {
      props.clickFx();
    }
  };
  return (
    <div className={`user-detail card ${props?.data?.componentClassName}`}>
      <Form className="row form-container" onSubmit={onFormSubmit}>
        {formContainer
          .filter(
            ({ groupname, fields }) =>
              !groupname || (groupname && groupname !== "Buttons")
          )
          .map(({ groupname, fields }, idx) => (
            <Fragment key={idx}>
              {groupname && (
                <div className="form_label text-center p-2 text-dark mb-4">
                  {groupname}
                </div>
              )}
              {fields.map((field, index) => {
                switch (field.type) {
                  case "Select":
                    return (
                      <Form.Group
                        className={`pb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label className="text-center">
                          {field.label}
                        </Form.Label>
                        <Form.Select
                          name={field?.name}
                          className={
                            field.colclass && field.colclass === "col-md-12"
                              ? "w-50"
                              : ""
                          }
                        >
                          {field.options.map((opt, i) => (
                            <option key={i}>{opt}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    );
                  case "State":
                    return (
                      <Form.Group
                        className={`pb-3 ${
                          field?.colclass ? field.colclass : ""
                        } `}
                        key={index}
                      >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Select
                          name="state"
                          className={
                            field.colclass && field.colclass === "col-md-12"
                              ? "w-50"
                              : ""
                          }
                        >
                          {states.map(({ name, abbreviation }, i) => (
                            <option value={abbreviation} key={i}>
                              {name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    );
                  case "Radio":
                    return (
                      <Form.Group
                        className={`pb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <div className="row">
                          <Form.Label>{field.label}</Form.Label>
                          {field.options.map((opt, idx) => (
                            <Form.Check
                              type="radio"
                              key={idx}
                              name={field?.name}
                              id={`opt-${idx}`}
                              value={opt}
                              label={opt}
                              className={`${
                                field?.optionClass ? field.optionClass : ""
                              } mx-3`}
                            />
                          ))}
                        </div>
                      </Form.Group>
                    );
                  case "Check":
                    return (
                      <Form.Check
                        type="checkbox"
                        id={field?.id}
                        label={field?.label}
                        name={field?.name}
                        className={`mb-3 mx-2 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      />
                    );
                  case "Image":
                    return (
                      <Form.Group
                        className={`mb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field?.label}</Form.Label>
                        {field?.details && (
                          <>
                            <Form.Control
                              type="file"
                              name={field?.name}
                              placeholder={field?.details?.placeholder}
                            />
                            <Form.Text className="text-muted">
                              {field?.details?.text}
                            </Form.Text>
                          </>
                        )}
                      </Form.Group>
                    );
                  case "Control":
                    return (
                      <Form.Group
                        className={`mb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field?.label}</Form.Label>
                        {field?.details && (
                          <>
                            <Form.Control
                              type={field?.details?.type}
                              name={field?.name}
                              placeholder={field?.details?.placeholder}
                              value={field?.details?.value}
                            />
                            <Form.Text className="text-muted">
                              {field?.details?.text}
                            </Form.Text>
                          </>
                        )}
                      </Form.Group>
                    );
                  case "Text":
                    return (
                      <Form.Group
                        className={`mb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field?.label}</Form.Label>
                        {field?.details && (
                          <>
                            <Form.Control
                              as="textarea"
                              type={field?.details?.type}
                              placeholder={field?.details?.placeholder}
                              name={field?.name}
                              style={{ height: "100px" }}
                              defaultValue={field?.details?.value}
                              row="4"
                              col="8"
                            />
                            <Form.Text className="text-muted">
                              {field?.details?.text}
                            </Form.Text>
                          </>
                        )}
                      </Form.Group>
                    );
                  case "TextArea":
                    return (
                      <Form.Group
                        className={`mb-3 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                        key={index}
                      >
                        <Form.Label>{field?.label}</Form.Label>
                        {field?.details && (
                          <>
                            <Form.Control
                              type={field?.details?.type}
                              placeholder={field?.details?.placeholder}
                              name={field?.name}
                              style={{ height: "100px" }}
                              value={field?.details?.value}
                            />
                            <Form.Text className="text-muted">
                              {field?.details?.text}
                            </Form.Text>
                          </>
                        )}
                      </Form.Group>
                    );
                  case "Review":
                    return (
                      field?.details && (
                        <div className="" key={index}>
                          <div className="" key={index}>
                            {Object.entries(user).map(([key, value], idx) => {
                              if (key !== "password") {
                                return (
                                  <span
                                    key={idx}
                                    className="d-block text-capitalize"
                                  >
                                    <b>{key}</b> : {value}
                                  </span>
                                );
                              }
                            })}
                          </div>
                          {field?.extra && <hr />}
                          {field?.extra &&
                            Object.entries(field?.extra).map(
                              ([key, value], idx) => {
                                return (
                                  <span
                                    key={idx}
                                    className="d-block text-capitalize"
                                  >
                                    <b>{key.split("_").join(" ")}</b> : {value}
                                  </span>
                                );
                              }
                            )}
                          {field?.extra && <hr />}
                        </div>
                      )
                    );
                  case "ImageCheck":
                    return (
                      <div
                        className={`mb-3 mx-2 ${
                          field?.colclass ? field.colclass : ""
                        }`}
                      >
                        {field?.details?.length > 0 &&
                          field?.details?.map((option, i) => {
                            return (
                              <div className="mb-4 mt-4 d-flex flex-column align-items-center justify-content-center">
                                {option?.src && (
                                  <img
                                    className={option?.className}
                                    src={option?.src}
                                    alt={option?.value}
                                  />
                                )}
                                <Form.Check
                                  type="checkbox"
                                  id={field?.id}
                                  label={option.value}
                                  name={field?.name}
                                  key={i}
                                />
                              </div>
                            );
                          })}
                      </div>
                    );
                  case "Calendar":
                    return (
                      <input
                        type="date"
                        id="start"
                        name="trip-start"
                        value={new Date().toLocaleDateString("en-CA")}
                        min={new Date().toLocaleDateString("en-CA")}
                        max={field?.details?.value_max}
                      />
                    );
                  case "Info":
                    return (
                      field?.details && (
                        <span className="fw-bold p-2" key={index}>
                          {field?.details}
                        </span>
                      )
                    );
                  default:
                    break;
                }
              })}
            </Fragment>
          ))}
        <div className="col-12">
          <div className="row">
            {formContainer
              .filter(({ groupname }) => groupname && groupname === "Buttons")
              .map(({ fields }, index) =>
                fields?.map((btn, idx) => (
                  <div
                    key={index + idx + 1}
                    className={`${btn?.colclass ? btn.colclass : ""}`}
                  >
                    {/* <Link to={btn?.link}> */}
                    {btn?.type === "submit" && (
                      <Button
                        variant={btn?.color}
                        type="submit"
                        onClick={
                          user?.username === email
                            ? () => {
                                window.analytics.track(
                                  "clicked " + btn?.buttonText,
                                  {
                                    title: btn?.buttonText,
                                  }
                                );
                                clicked = btn;
                              }
                            : () => handleShow()
                        }
                        className="w-100"
                      >
                        {btn?.buttonText}
                      </Button>
                    )}
                    {!btn?.type && (
                      <Button
                        variant={btn?.color}
                        onClick={() => btnClick(btn)}
                        className="w-100"
                      >
                        {btn?.buttonText}
                      </Button>
                    )}
                    {/* </Link> */}
                  </div>
                ))
              )}
          </div>
        </div>
      </Form>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your order can't be places, Please contact customer care
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default FormContainer;
