import React, { useEffect, useState } from "react";
import Chats from "../Chats/chats";
import User from "../../helpers/login/userData";
import Navigation from "../Common/navigation";
import Switch from "../../switch/switch.demo";
import axios from "axios";
import Config from "../../config";
/**
 *
 * @Components props.data
 * name: component name (not user for rendering)
 * notes : copyright info,
 * img: company logo,
 * items[]: footerlinks contains array of {url, name}
 */

const Footer = (props) => {
  const user = User();
  const { data, site } = props;
  /*GLANCE COBROWSE INIT*/
  function readOutrequest() {
    const agent = user.email;
    //const agent = "adarsh-banking@teamhgs.com";
    const content = [
      "Rewards & Benefits:You are automatically enrolled in the Membership Rewards program which provides you with points for spending on the card.You earn 3 Membership Rewards points per dollar on travel worldwide including airfare, hotels, transit, taxis, tours, and ride shares.You also earn 3 Membership Rewards points per dollar at restaurants worldwide.And you earn 1 point per dollar on other purchases.Get up to $100 per calendar year in statement credits for your CLEAR membership. CLEAR is an identity verification system that uses biometrics like fingerprints and can make traveling easier by getting you through security faster at select U.S. airports.Enjoy up to $100 in statement credits per calendar year for purchases of lounge access through LoungeBuddy to any of the airport lounges in the LoungeBuddy networkOnly purchases of goods and services, minus returns and credits, are eligible to earn points. Membership Rewards points can be used towards travel, dining, shopping, and merchandise. The value of Membership Rewards points varies according to how you choose to use them. To learn more, go to membershiprewards.com/pointsinfo.",
      "Welcome Offer Details:There is a welcome offer. You can earn 40,000 Membership Rewards points after you make purchases totaling $2,000 or more on the Card in the first 6 months starting from the date your account is approved.Welcome offer not available to applicants who have or have had this Card or previous versions of the American Express® Green Card. We may also consider the number of American Express Cards you have opened and closed as well as other factors in making a decision on your welcome offer eligibility",
    ];
    const body = {
      adsId: agent,
      disclosures: content,
    };
    console.log("ReadOut trigger", body);
    const headers = {
      "Content-Type": "application/json",
      "agentx-apikey": "Agentx",
    };

    axios
      .post(Config.readOutApi, body, {
        headers: headers,
        withCredentials: false,
      })
      .then((response) => {
        console.log("ReadOut trigger sent", response);
      })
      .catch((error) => {});
  }

  function startSession() {
    if (window["GLANCE"]) {
      console.log("Glance loaded", window.GLANCE);
      window.GLANCE.Cobrowse.Visitor.startSession();
      console.log("GLANCE  SESSION has Started");
    } else {
      console.log("THERE ARE NO AVAILABLE AGENTS for COBROWSE ");
    }
  }

  return (
    <footer className="footer  p-3">
      <div className="row">
        <div className="col-lg-4 col-sm-12 col-md-3 py-3 font-0_7">
          {data && data.notes}
        </div>
        <div className="col-lg-4 col-sm-12 col-md-5 py-3 justify-content-center align-items-center d-flex ">
          <img src={data && data.img} alt="" className="hgs-logo" />
        </div>
        <div className="col-lg-4 col-sm-12 col-md-4 m-auto font-0_7 ">
          {
            data && data.navigations && (
              // data.navigations.map((nav, idx) => (
              <Navigation data={data.navigations} />
            )
            // ))
          }
        </div>
      </div>
      <div className="row">
        {user && (
          <ul className="col-lg-12 col-sm-12 col-md-12 py-3 font-0_7 footer-links">
            <li>
              <a
                href="#CoBrowse"
                id="start-cobrowse-jS"
                className={`btn btn-primary show }`}
                onClick={() => startSession()}
              >
                Start Cobrowse Session with an Agent
              </a>
            </li>
            <li>
              {" "}
              <a
                href="#readOut"
                id="start-readOut "
                className={`btn btn-primary show }`}
                onClick={() => readOutrequest()}
              >
                ReadOut
              </a>
            </li>
          </ul>
        )}
      </div>
      <div className="debugger badge badge-primary d-flex align-items-center justify-content-start fs-8">
        {JSON.parse(window.localStorage.getItem("staticProjectName"))}
        ::{JSON.parse(window.localStorage.getItem("staticCallCenter"))}
      </div>

      <Switch />
      <div id="twilio-webchat-widget-root"></div>
      {user?.email && window.MoveoAI && console.log("MoveoAI INITIATED FOOTER")}
      <Chats {...props} />
    </footer>
  );
};
export default Footer;
